export const environment = {
    production: true,
    apiUrl: 'https://api.ohmynote.com/',
    // apiUrl: 'http://localhost:8000/',  // for local
    // apiUrl: 'http://10.0.2.2:8000/', // for Android emulator
    cunaUrl:'https://api.kuna.io/v3/',
    firebaseConfig: {
        apiKey: "AIzaSyAUeZahgbAexP9lEPjtZk2uSvbiopfdA7k",
        dbUrl: "https://crypto-portfolio-3f56c-default-rtdb.europe-west1.firebasedatabase.app/",
        authUrl: "https://identitytoolkit.googleapis.com/v1/",
        authDomain: "crypto-portfolio-3f56c.firebaseapp.com",
        projectId: "crypto-portfolio-3f56c",
        storageBucket: "crypto-portfolio-3f56c.appspot.com",
        messagingSenderId: "267683562038",
        appId: "1:267683562038:web:098c056dc5cadbc371ce97"
    }
};
