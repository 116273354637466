import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'get-started',
    loadChildren: () => import('./pages/get-started/get-started.module').then(m => m.GetStartedPageModule)
  },
  {
    path: 'story-text',
    loadChildren: () => import('./pages/story-text/story-text.module').then(m => m.StoryTextPageModule)
  },
  {
    path: 'story-ingredients',
    loadChildren: () => import('./pages/story-ingredients/story-ingredients.module').then(m => m.StoryIngredientsPageModule)
  },
  {
    path: 'story-video',
    // loadChildren: () => StoryVideoPageModule,
    loadChildren: () => import('./pages/story-video/story-video.module').then(m => m.StoryVideoPageModule),
  },
  {
    path: 'story-audio',
    // loadChildren: () => StoryAudioPageModule
    loadChildren: () => import('./pages/story-audio/story-audio.module').then(m => m.StoryAudioPageModule)
  },
  {
    path: 'story-play',
    loadChildren: () => import('./pages/story-play/story-play.module').then(m => m.StoryPlayPageModule)
  },
  {
    path: 'story-ingredients-prepare',
    loadChildren: () => import('./pages/story-ingredients-prepare/story-ingredients-prepare.module').then(m => m.StoryIngredientsPreparePageModule)
  },
  {
    path: 'story-ingredients-results',
    loadChildren: () => import('./pages/story-ingredients-results/story-ingredients-results.module').then(m => m.StoryIngredientsResultsPageModule)
  },
  {
    path: 'story-ingredients-all',
    loadChildren: () => import('./pages/story-ingredients-all/story-ingredients-all.module').then(m => m.StoryIngredientsAllPageModule)
  },
  {
    path: 'story-products',
    loadChildren: () => import('./pages/story-products/story-products.module').then(m => m.StoryProductsPageModule)
  },
  {
    path: 'story-product-single',
    loadChildren: () => import('./pages/story-product-single/story-product-single.module').then(m => m.StoryProductSinglePageModule)
  },
  {
    path: 'welcome-scanner',
    loadChildren: () => import('./pages/welcome-scanner/welcome-scanner.module').then(m => m.WelcomeScannerPageModule)
  },
  {
    path: 'welcome-result',
    loadChildren: () => import('./pages/welcome-result/welcome-result.module').then(m => m.WelcomeResultPageModule)
  },
  {
    path: 'story-video-player',
    loadChildren: () => import('./pages/story-video-player/story-video-player.module').then( m => m.StoryVideoPlayerPageModule)
  },
  {
    path: 'story-video-native',
    loadChildren: () => import('./pages/story-video-native/story-video-native.module').then( m => m.StoryVideoNativePageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'story-results',
    loadChildren: () => import('./pages/story-results/story-results.module').then(m => m.StoryResultsPageModule)
  },
  {
    path: 'story-choose-scents',
    loadChildren: () => import('./pages/story-choose-scents/story-choose-scents.module').then( m => m.StoryChooseScentsPageModule)
  },
  {
    path: 'story-choose-category',
    loadChildren: () => import('./pages/story-choose-category/story-choose-category.module').then( m => m.StoryChooseCategoryPageModule)
  },
  {
    path: 'story-your-fragrance',
    loadChildren: () => import('./pages/story-your-fragrance/story-your-fragrance.module').then( m => m.StoryYourFragrancePageModule)
  },
  {
    path: 'story-save-fragrance',
    loadChildren: () => import('./pages/story-save-fragrance/story-save-fragrance.module').then( m => m.StorySaveFragrancePageModule)
  },
  {
    path: 'story-your-fragrance-basket',
    loadChildren: () => import('./pages/story-your-fragrance-basket/story-your-fragrance-basket.module').then( m => m.StoryYourFragranceBasketPageModule)
  },
  {
    path: 'basket-added',
    loadChildren: () => import('./pages/basket-added/basket-added.module').then( m => m.BasketAddedPageModule)
  },
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
