import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {IonInput} from '@ionic/angular';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss'],
})
export class MessageBarComponent {
  isTyping = false;
  message = '';
  sendMessageButtonEnabled = false;
  @Output() sendMessage = new EventEmitter();
  @ViewChild('messageInput') messageInput!: IonInput;

  constructor() { }

  onMessageChange(text: string) {
    const messageLength = text.trim().length;
    if (this.sendMessageButtonEnabled !== (messageLength >= 2)) {
      this.sendMessageButtonEnabled = !this.sendMessageButtonEnabled;
    }
  }

  setFocus() {
    setTimeout(() => this.messageInput.setFocus(), 0);
  }

  onTextAreaFocus() {
    if (!this.isTyping) {
      this.isTyping = true;
      this.setFocus();
    }
  }

  onTextAreaBlur() {
    if (this.isTyping && !this.message.trim()) {
      this.isTyping = false;
    }
  }

  onMessageSend() {
    const msg = this.message.trim();
    if (msg) {
      this.sendMessage.emit({ isUser: true, text: msg });
      this.message = '';
      this.sendMessageButtonEnabled = false;
      this.setFocus();
    }
  }
}
