import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ingredient-item',
  templateUrl: './ingredient-item.component.html',
  styleUrls: ['./ingredient-item.component.scss'],
})
export class IngredientItemComponent implements OnInit {
  itemDescription: string;
  itemName: string;
  @Input() disabled: boolean;
  @Input() item: any;
  constructor() {}

  ngOnInit() {
    this.item.flip = false;

    this.itemName = this.item.name.charAt(0).toUpperCase() + this.item.name.slice(1);

    let description = this.item.description ? this.item.description : '';
    if (description.length && description.slice(-1) !== '.') {
      description += '.';
    }
    let emotions = this.item.emotions ? this.item.emotions : '';
    if (emotions.length) {
      emotions = emotions.replace('.', '').split(',').map((e: string) => e.trim()).join(', ');
      emotions = emotions.charAt(0).toUpperCase() + emotions.slice(1);
    }

    this.itemDescription = description.length + emotions.length ? description + ' ' + emotions : 'No description';
  }
}
