import { Component, Input } from '@angular/core';

export interface ChatMessage {
  nextPromptDelay?: number;
  isUser?: boolean;
  text: string;
  userMessageRequired?: boolean;
}

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent {
  @Input() message: ChatMessage;
  @Input() showProfileImage: boolean;

  constructor() { }
}
