import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header-chat',
  templateUrl: './header-chat.component.html',
  styleUrls: ['./header-chat.component.scss'],
})
export class HeaderChatComponent {
  @Input() continueDisabled?: boolean = true;
  @Input() showContinueButton?: boolean = false;
  @Output() back = new EventEmitter();
  @Output() continue? = new EventEmitter();

  constructor() { }

  goBack() {
    this.back.emit();
  }

  onContinue() {
    this.continue.emit();
  }
}
