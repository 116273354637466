import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { HeaderComponent } from './header/header.component';
import { HeaderBlueComponent } from './header-blue/header-blue.component';
import { HeaderChatComponent } from './header-chat/header-chat.component';
import { FragranceComponent } from './fragrance/fragrance.component';
import { IngredientItemComponent } from './ingredient-item/ingredient-item.component';
import { MessageBarComponent } from './message-bar/message-bar.component';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { SubheaderTabsComponent } from './subheader-tabs/subheader-tabs.component';
import { SubheaderFiltersComponent } from './subheader-filters/subheader-filters.component';
import { SubheaderGenderFiltersComponent } from './subheader-gender-filters/subheader-gender-filters.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '../helpers/translate.helper';

@NgModule({
  declarations: [
    ChatMessageComponent,
    HeaderComponent,
    HeaderBlueComponent,
    HeaderChatComponent,
    FragranceComponent,
    IngredientItemComponent,
    MessageBarComponent,
    ProductCategoryComponent,
    ProductItemComponent,
    SubheaderTabsComponent,
    SubheaderFiltersComponent,
    SubheaderGenderFiltersComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    ChatMessageComponent,
    HeaderComponent,
    HeaderBlueComponent,
    HeaderChatComponent,
    FragranceComponent,
    IngredientItemComponent,
    MessageBarComponent,
    ProductCategoryComponent,
    ProductItemComponent,
    SubheaderTabsComponent,
    SubheaderFiltersComponent,
    SubheaderGenderFiltersComponent,
  ],
})
export class ComponentsModule {}
